<template>
  <div class="my-points">
    <div class="myp-th">
      <div class="myp-time">Time</div>
      <div class="myp-name">Article Name</div>
      <div class="myp-notes">Notes</div>
      <div class="myp-points">Points</div>
    </div>
    <el-scrollbar height="720px">
      <ul v-if="points.length !== 0">
        <li v-for="(point, index) in points" :key="index">
          <div class="myp-time">{{ dateFormat(point.timeAdded) }}</div>
          <div class="myp-name">
            {{ names[index] != null ? names[index] : 'Unknown Article'}}
          </div>
          <div class="myp-notes">{{ point.notes }}</div>
          <div class="myp-points">+{{point.points}}</div>
        </li>
      </ul>
      <el-empty v-else description="No Data"/>
    </el-scrollbar>

  </div>
</template>

<script>
import {get,post} from "@/api";
import {useRoute} from "vue-router";
import {ref} from "vue";
import {formatDateTime} from "@/utils/dateFormatter";

export default {
  name: "PointsHistory",
  setup() {
    const points = ref(0);
    const route = useRoute();
    const names = ref([]);

    function dateFormat(date) {
      let old = new Date(date)
      return formatDateTime(old, 'yyyy-MM-dd HH:mm:ss')
    }

    function getArticleNames(){
      for (let i in points.value ) {
        post('/article/id', {id:points.value[i].articleId}).then(res=> {
          names.value[i] = res.name
        })
      }
    }
    function load() {
      post('/point/get',{uid:route.query.uid} ).then(res => {
        points.value = res.points
        names.value = new Array(res.points.length).fill("")
        getArticleNames()
      })
    }
    load()

    return{
      points,
      names,
      dateFormat
    }
  }
}
</script>

<style scoped>
.my-points{
  display: block;
  padding: 20px 25px;
}
.my-points .myp-th{
  display: flex;
  font-family: "Poppins-SemiBold";
  font-size: 18px;
  color: #111880;
  padding: 20px 0;
  border-bottom: 1px solid #DADADA;
}
.my-points .myp-time{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  text-align: center;
  padding: 0 15px;
}
.my-points .myp-name{
  width: 50%;
  text-align: left;
  padding: 0 15px;
}
.my-points .myp-notes{
  width: 25%;
  text-align: left;
  padding: 0 15px;
}
.my-points .myp-points{
  width: 10%;
  text-align: center;
  padding: 0 15px;
}
.my-points ul{
  display: block;
  height: 475px;
  /*overflow-y: overlay;*/
  -webkit-overflow-scrolling: touch;
}
.my-points ul::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  position: absolute;
}
.my-points ul::-webkit-scrollbar-thumb {
  background: #D9D9D9;
  border-radius: 5px;
}
.my-points li{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "Poppins-Medium";
  line-height: 1.5;
  padding: 20px 0;
  border-bottom: 1px solid #DADADA;
}
</style>